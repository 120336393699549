// basic stuff
import React, { useEffect, useState } from "react"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useMutation, useQuery } from "react-query"
import { navigate } from "gatsby"
import produce from "immer"

// components
import { FormGroup, Label, Input } from "reactstrap"
import Layout from "../../../components/layout"
import ProfileLayout from "../../../components/UserProfileLayout"
import Seo from "../../../components/seo"
import Tali from "../../../components/Tali/TaliContainer.js"
import {
  TextInput,
  NumberInput,
} from "../../../components/UserProfileFormBlocks"
import PasswordInput from "../../../components/UserProfileFormBlocks/PasswordInput.js"
import { handleJWTtimeout } from "../../../components/handleJWTtimeout.js"

// global styles
import { MainButton } from "../../../styles/buttons.js"
import { createColorGradient } from "../../../styles/helpers"
import { theme } from "../../../styles/theme"
import { Tooltip } from "../../../styles/common"

// icons
import PlusIcon from "../../../lib/icons/Plus.js"

// graphQl
import graphqlClient, {
  resetGraphQLClient,
} from "../../../services/graphql-client.js"
import {
  CURRENT_USER_QUERY,
  TEACHER_BY_ID_QUERY,
  UPDATE_TEACHER_BY_ID_MUTATION,
  UPDATE_TEACHER_PASSWORD_BY_ID_MUTATION,
  DELETE_TEACHER_BY_ID_MUTATION,
  CREATE_CLASS_MUTATION,
  UPDATE_CLASS_NAME_BY_ID_MUTATION,
  UPDATE_CLASS_YEAR_BY_ID_MUTAION,
  DELETE_CLASS_BY_ID_MUTATION,
  UPDATE_SCHOOL_NAME_BY_ID_MUTATION,
  CREATE_STUDENT_MUTATION,
  UPDATE_STUDENT_PROFICIENCY_BY_ID_MUTAION,
  UPDATE_STUDENT_MISTAKE_PROGNOSIS_BY_ID_MUTATION,
  UPDATE_STUDENT_ESTIMATED_SKILL_BY_ID_MUTATION,
  DELETE_STUDENT_BY_ID_MUTATION,
  UPDATE_STUDENT_NAME_BY_ID_MUTATION,
  UPDATE_STUDENT_DAILY_TARGET_BY_ID_MUTATION,
} from "../../../graphql_requests"

// hooks
import { useSSRStorage } from "../../../hooks/index.js"

// helpers
import { isJWTExpired } from "../../../services/helpers"

// constants
import { DEFAULT_MISTAKE_PROGNOSIS } from "../../../const_values"

//local styles
const StyledActionButton = styled(MainButton)`
  ${createColorGradient("#FBCA82", "#FF9766", "to right")}
  width: min-content;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6vw + 12px);
  position: relative;
`

const EditButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      title={props.title}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}
      ✏️
    </StyledActionButton>
  )
}

const DeleteButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      onKeyDown={props.handleKeyPress}
      title={props.title}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}⛔
    </StyledActionButton>
  )
}

const DashboardButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={{
        fontSize: "calc(0.6vw + 16px)",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}
      📰
    </StyledActionButton>
  )
}

const PDFDownloadButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}
      📥
    </StyledActionButton>
  )
}

const AddButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}
      <PlusIcon />
    </StyledActionButton>
  )
}

// main component
const ProfilePage = () => {
  // check if executing instance is the browser or the building process
  const isBrowser = typeof window !== "undefined"

  // jwt from local storage
  const [jwt] = useSSRStorage("jwt_teacher")

  const { width, height } = useWindowSize()

  // states
  const [teacherId, setTeacherId] = useState("")
  const [teacher, setTeacher] = useState()
  const [teacherEditMode, setTeacherEditMode] = useState(false)
  const [currentClassEdit, setCurrentClassEdit] = useState(null)
  const [currentSchoolEdit, setCurrentSchoolEdit] = useState(null)
  const [currentClassEditSchool, setCurrentClassEditSchool] = useState(null)
  const [creatingNewClass, setCreatingNewClass] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [globalSkillLevel, setGlobalSkillLevel] = useState("")
  const [globalDailyTarget, setGlobalDailyTarget] = useState(15)

  useQuery(
    ["currentUserIdQuery"],
    () => graphqlClient.request(CURRENT_USER_QUERY),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log(data)
        if (data.currentUserId) {
          setTeacherId(data.currentUserId)
          console.log("login success!")
          console.log({ data })
        }
      },
      onError: (error) => {
        handleJWTtimeout(error)
      },
    }
  )

  useQuery(
    ["teacherByIdQuery", teacherId],
    () => graphqlClient.request(TEACHER_BY_ID_QUERY, { id: teacherId }),
    {
      enabled: !!teacherId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onSuccess: (data) => {
        console.log("teacher:", data)
        setTeacher(data.teacherById)
      },
    }
  )

  const updateTeacherMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_TEACHER_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateTeacherPasswordMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_TEACHER_PASSWORD_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteTeacherMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_TEACHER_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteStudentMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_STUDENT_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteClassMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_CLASS_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateClassNameMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_CLASS_NAME_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateClassYearMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_CLASS_YEAR_BY_ID_MUTAION, variables),
    {
      onSuccess: (data) => console.log(data),
      onError: (error) => console.error(error),
    }
  )

  const updateSchoolNameMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_SCHOOL_NAME_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateStudentNameMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_STUDENT_NAME_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateStudentProficiencyMutation = useMutation(
    (variables) =>
      graphqlClient.request(
        UPDATE_STUDENT_PROFICIENCY_BY_ID_MUTAION,
        variables
      ),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateStudentMistakePrognosisMutation = useMutation(
    (variables) =>
      graphqlClient.request(
        UPDATE_STUDENT_MISTAKE_PROGNOSIS_BY_ID_MUTATION,
        variables
      ),
    {
      onSuccess: (data) => {
        console.log("update student mistake prognosis:", data)
      },
      onError: (error) => {
        console.error("failed to update student mistate prognosis:", error)
      },
    }
  )

  const updateStudentEstimatedSkillMutation = useMutation(
    (variables) =>
      graphqlClient.request(
        UPDATE_STUDENT_ESTIMATED_SKILL_BY_ID_MUTATION,
        variables
      ),
    {
      onSuccess: (data) => {
        console.log("update student estimated skill:", data)
      },
      onError: (error) => {
        console.error("failed to update student estimated skill:", error)
      },
    }
  )

  const updateStudentDailyTargetMutation = useMutation(
    (variables) =>
      graphqlClient.request(
        UPDATE_STUDENT_DAILY_TARGET_BY_ID_MUTATION,
        variables
      ),
    {
      onSuccess: (data) => {
        console.log("update student daily target:", data)
      },
      onError: (error) => {
        console.error("failed to update student daily target:", error)
      },
    }
  )

  const createClassMutation = useMutation(
    (variables) => graphqlClient.request(CREATE_CLASS_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error("Error on create class mutation:", error)
      },
    }
  )

  const createStudentMutation = useMutation(
    (variables) => graphqlClient.request(CREATE_STUDENT_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const exportStudents = (schoolId, classId) => {
    const unit = "pt"
    const size = "A4"
    const orientation = "portrait"
    const marginLeft = 40
    const title = "My Class"
    const headers = [["Name", "Alias", "Access Code", "QR-Code"]]
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    const schoolClass = school.classesBySchool.nodes.find(
      (c) => c.id === classId
    )
    const students = schoolClass.studentsByClass.nodes.map((student) => [
      student.name,
      student.alias,
      student.login,
    ])

    const pdfTableConfig = {
      theme: "grid",
      headStyles: {
        minCellHeight: 0,
        fontSize: 12,
        fillColor: theme.colors.primary.main,
        lineWidth: 1,
        lineColor: 0,
      },
      bodyStyles: {
        fontSize: 16,
        minCellHeight: 85,
        minCellWidth: 75,
        valign: "middle",
        cellWidth: "auto",
        lineWidth: 1,
        lineColor: 0,
      },
      startY: 60,
      pageBreak: "auto",
      rowPageBreak: "avoid",
      head: headers,
      body: students,
      didDrawCell: (data) => {
        if (data.column.index === 3 && data.cell.section === "body") {
          const qrCodeSize = data.cell.height - 4
          const qrCodeSrc = `https://chart.googleapis.com/chart?cht=qr&chs=${qrCodeSize}x${qrCodeSize}&chl=app.talidu.net/student/profile?login=${
            students[data.row.index][2]
          }`
          const qrCodePositionX =
            data.cell.x + Math.floor(data.cell.width / 2 - qrCodeSize / 2)
          const qrCodePositionY =
            data.cell.y + Math.floor(data.cell.height / 2 - qrCodeSize / 2)
          doc.addImage(
            qrCodeSrc,
            "JPEG",
            qrCodePositionX,
            qrCodePositionY,
            qrCodeSize,
            qrCodeSize
          )
        }
      },
    }

    const doc = new jsPDF(orientation, unit, size)

    doc.text(title + `: ${schoolClass.name}`, marginLeft, 40)
    doc.autoTable(pdfTableConfig)
    doc.save(`students-list${schoolClass.name}.pdf`)
  }

  const handleChange = (e) => {
    switch (e.target.id) {
      case "teacher-name":
        setTeacher((t) => ({ ...t, name: e.target.value }))
        break
      case "teacher-email":
        setTeacher((t) => ({ ...t, email: e.target.value }))
        break
      case "teacher-password":
        setTeacher((t) => ({ ...t, password: e.target.value }))
        break
      default:
        break
    }
  }

  const handleTeacherEditKeypress = (e) => {
    console.log("Keypress Event:", e)
    if (e.key !== "Tab") {
      e.preventDefault()
      if (e.key === "Enter") {
        handleChange(e)
        setTeacherEditMode(false)
      }
    }
  }

  const handleClassEditKeypress = ({ e, schoolId, classId }) => {
    if (e.key !== "Tab") {
      if (e.key === "Enter") {
        e.preventDefault()
        handleChangeClassSave({ schoolId, classId })
      }
    } else {
      e.preventDefault()
      let inputElements = "#class-name,#student-name"

      if (document.activeElement && document.activeElement.form) {
        var focussable = Array.prototype.filter.call(
          document.querySelectorAll(inputElements),
          function (element) {
            //check for visibility while always include the current activeElement
            return (
              element.offsetWidth > 0 ||
              element.offsetHeight > 0 ||
              element === document.activeElement
            )
          }
        )
        var index = focussable.indexOf(document.activeElement)
        if (index > -1) {
          var nextElement = focussable[index + 1] || focussable[0]
          nextElement.focus()
        }
      }
    }
  }

  const handleSave = (e) => {
    e.preventDefault()
    const name = e.target["teacher-name"].value
    const email = e.target["teacher-email"].value
    updateTeacherMutation.mutate({ name, email, id: teacherId })

    const password = e.target["teacher-password"].value
    if (password.length > 0) {
      updateTeacherPasswordMutation.mutate({ password, id: teacherId })
    }
  }

  const deleteAccount = () => {
    const confirmed = isBrowser
      ? window.confirm(
          // " \n\nMöchten Sie wirklich Ihr Profil und die Verknüpfung zu allen Ihren Klassen und Schülern löschen?"
          "Are you sure you want to delete your profile, including the link to all of your classes and students?"
        )
      : false

    if (confirmed) {
      console.log("delete!")
      deleteTeacherMutation.mutate({ id: teacherId })
      logout()
    }
  }

  const logout = () => {
    console.log("logout!")
    // setJwt(null) // FIXME: this should work, but doesn't do anything
    if (isBrowser) window.localStorage.removeItem("jwt_teacher") // workaround
    resetGraphQLClient()
    navigate("/")
  }

  const handleStudentsAdd = async (schoolId, classId) => {
    console.log("add students", { schoolId }, { classId })
    let studentCount = prompt("How many students do you want to add?", 10)
    if (
      studentCount != null &&
      !isNaN(parseInt(studentCount, 10)) &&
      parseInt(studentCount, 10) > 0
    ) {
      console.log("add ", studentCount, "students")
      for (let i = 0; i < studentCount; i = i + 1) {
        try {
          const createStudent = await createStudentMutation.mutateAsync({
            class: classId,
          })
          const student = createStudent.createStudent.student
          const school = teacher.schoolsByContact.nodes.find(
            (school) => school.id === schoolId
          )
          const schoolClass = school.classesBySchool.nodes.find(
            (schoolClass) => schoolClass.id === classId
          )

          if (!student.proficiency) {
            updateStudentProficiencyMutation.mutate({
              id: student.id,
              proficiency: schoolClass.year,
            })
          }

          if (!student.mistakePrognosis) {
            updateStudentMistakePrognosisMutation.mutate({
              id: student.id,
              mistakePrognosis: DEFAULT_MISTAKE_PROGNOSIS,
            })
          }

          setTeacher(
            produce((draft) => {
              const school = draft.schoolsByContact.nodes.find(
                (school) => school.id === schoolId
              )
              const schoolClass = school.classesBySchool.nodes.find(
                (schoolClass) => schoolClass.id === classId
              )
              schoolClass.studentsByClass.nodes.push(student)
            })
          )
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const handleClassAdd = async (schoolId) => {
    console.log("add class", { schoolId })
    // const newClassId = createClassMutation.mutate({ school: schoolId, name: "" })

    try {
      const createClass = await createClassMutation.mutateAsync({
        school: schoolId,
        name: "",
        year: 1,
      })
      const newClassId = createClass.createClass.class.id

      console.log("new class id:", newClassId)
      setCreatingNewClass(true)
      setTeacher(
        produce((draft) => {
          const school = draft.schoolsByContact.nodes.find(
            (school) => school.id === schoolId
          )
          school.classesBySchool.nodes.push({
            id: newClassId,
            name: "",
            year: 1,
            studentsByClass: { nodes: [] },
          })
        })
      )
      setCurrentClassEdit(newClassId)
    } catch (error) {
      console.error("Error on class add:", error)
    }
    // finally {
    //   console.log('done')
    // }
  }

  const handleClassEdit = ({ classId, schoolId }) => {
    console.log("edit class", { classId })
    setCurrentClassEdit(classId)
    setCurrentClassEditSchool(schoolId)
  }

  const handleChangeClassYear = (e, schoolId, classId) => {
    console.log("edit class year", { classId }, e.target.value)
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        const schoolClass = school.classesBySchool.nodes.find(
          (c) => c.id === classId
        )
        schoolClass.year = e.target.value
      })
    )
  }

  const handleSchoolEdit = (schoolId) => {
    console.log("edit school", { schoolId })
    setCurrentSchoolEdit(schoolId)
  }

  const handleStudentDelete = ({ schoolId, classId, studentId }) => {
    console.log("delete student", { studentId })
    const confirmed = isBrowser
      ? window.confirm(
          // "\n\nMöchten Sie wirklich das Schülerprofil und alle gespeicherten Lernerfolge löschen?"
          "Are you sure you want to delete the student‘s profile including the history of their learning achievements?"
        )
      : false

    if (confirmed) {
      console.log("delete!")
      deleteStudentMutation.mutate({ id: studentId })
      setTeacher(
        produce((draft) => {
          const school = draft.schoolsByContact.nodes.find(
            (school) => school.id === schoolId
          )
          const schoolClass = school.classesBySchool.nodes.find(
            (schoolClass) => schoolClass.id === classId
          )
          const studentIndex = schoolClass.studentsByClass.nodes.findIndex(
            (student) => student.id === studentId
          )
          schoolClass.studentsByClass.nodes.splice(studentIndex, 1)
        })
      )
    }
  }

  const handleClassDelete = ({ schoolId, classId }) => {
    console.log("delete class", { classId })
    const confirmed = isBrowser
      ? window.confirm("Do you really want to delete this class?")
      : false

    if (confirmed) {
      deleteClassMutation.mutate({ id: classId })
      setTeacher(
        produce((draft) => {
          const school = draft.schoolsByContact.nodes.find(
            (school) => school.id === schoolId
          )
          const classIndex = school.classesBySchool.nodes.findIndex(
            (c) => c.id === classId
          )
          school.classesBySchool.nodes.splice(classIndex, 1)
        })
      )
    }
  }

  const handleChangeClassName = ({ e, schoolId, classId }) => {
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        const schoolClass = school.classesBySchool.nodes.find(
          (schoolClass) => schoolClass.id === classId
        )
        schoolClass.name = e.target.value
      })
    )
  }

  const handleChangeSchoolName = ({ e, schoolId }) => {
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        school.name = e.target.value
      })
    )
  }

  const handleChangeStudentName = ({ e, schoolId, classId, studentId }) => {
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        const schoolClass = school.classesBySchool.nodes.find(
          (schoolClass) => schoolClass.id === classId
        )
        const student = schoolClass.studentsByClass.nodes.find(
          (student) => student.id === studentId
        )
        student.name = e.target.value
      })
    )
  }

  const handleStudentEstimatedSkillSelect = ({
    e,
    schoolId,
    classId,
    studentId,
  }) => {
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        const schoolClass = school.classesBySchool.nodes.find(
          (schoolClass) => schoolClass.id === classId
        )
        const student = schoolClass.studentsByClass.nodes.find(
          (student) => student.id === studentId
        )
        student.estimatedSkill = e.target.value
      })
    )
  }

  const handleStudentDailyTargetSelect = ({
    e,
    schoolId,
    classId,
    studentId,
  }) => {
    setTeacher(
      produce((draft) => {
        const school = draft.schoolsByContact.nodes.find(
          (school) => school.id === schoolId
        )
        const schoolClass = school.classesBySchool.nodes.find(
          (schoolClass) => schoolClass.id === classId
        )
        const student = schoolClass.studentsByClass.nodes.find(
          (student) => student.id === studentId
        )
        student.dailyTarget = e.target.value
      })
    )
  }

  const handleGlobalEstimatedSkillSelect = (e) => {
    if (e.target.value) setGlobalSkillLevel(e.target.value)
  }

  const handleGlobalDailyTargetSelect = (e) => {
    if (e.target.value) setGlobalDailyTarget(e.target.value)
  }

  const handleChangeClassSave = ({ schoolId, classId }) => {
    console.log("save class", { classId })
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    const schoolClass = school.classesBySchool.nodes.find(
      (c) => c.id === classId
    )
    const newClassName = schoolClass.name
    schoolClass.studentsByClass.nodes.forEach((student) => {
      if (student.name) {
        updateStudentNameMutation.mutate({
          id: student.id,
          name: student.name,
        })
      }

      if (!student.proficiency || student.proficiency < schoolClass.year) {
        updateStudentProficiencyMutation.mutate({
          id: student.id,
          proficiency: schoolClass.year,
        })
      }

      updateStudentEstimatedSkillMutation.mutate({
        id: student.id,
        estimatedSkill: student.estimatedSkill,
      })

      updateStudentDailyTargetMutation.mutate({
        id: student.id,
        dailyTarget: student.dailyTarget,
      })
    })

    updateClassNameMutation.mutate({ id: classId, name: newClassName })
    updateClassYearMutation.mutate({ id: classId, year: schoolClass.year })

    setCurrentClassEdit(null)
    setCurrentClassEditSchool(null)
    if (creatingNewClass) setCreatingNewClass(false)
  }

  const handleChangeSchoolNameSave = ({ e, schoolId }) => {
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    updateSchoolNameMutation.mutate({ id: schoolId, name: school.name })
    setCurrentSchoolEdit(null)
  }

  useEffect(() => {
    // authenticate against database
    if ((!jwt || isJWTExpired(jwt)) && isBrowser) navigate("/teacher")
    else if (jwt) {
      graphqlClient.setHeader("authorization", `Bearer ${jwt}`)
      setIsAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    if (currentClassEdit && currentClassEditSchool) {
      setTeacher(
        produce((draft) => {
          const school = draft.schoolsByContact.nodes.find(
            (school) => school.id === currentClassEditSchool
          )
          const schoolClass = school.classesBySchool.nodes.find(
            (schoolClass) => schoolClass.id === currentClassEdit
          )
          schoolClass.studentsByClass.nodes.forEach((student) => {
            student.estimatedSkill = globalSkillLevel
          })
        })
      )
    }
  }, [globalSkillLevel])

  useEffect(() => {
    if (currentClassEdit && currentClassEditSchool) {
      setTeacher(
        produce((draft) => {
          const school = draft.schoolsByContact.nodes.find(
            (school) => school.id === currentClassEditSchool
          )
          const schoolClass = school.classesBySchool.nodes.find(
            (schoolClass) => schoolClass.id === currentClassEdit
          )
          schoolClass.studentsByClass.nodes.forEach((student) => {
            student.dailyTarget = globalDailyTarget
          })
        })
      )
    }
  }, [globalDailyTarget])

  return (
    <Layout layoutHeight={height}>
      <Seo title="Profile" />
      <ProfileLayout backLinkTarget="/">
        {teacher && (
          <>
            <Tali
              size={(width / 800) * 0.8}
              mood="happy"
              position="top"
              float={false}
              messageStyle="glow"
            >
              <p className="tali-question">
                Welcome to your profile, {teacher.name}!<br />
                Here you can manage your personal data and supervise your
                classes.
              </p>
            </Tali>
            <h2>Personal Data</h2>
            {teacherEditMode ? (
              <form
                onSubmit={(e) => {
                  handleSave(e)
                  setTeacherEditMode(false)
                }}
              >
                <TextInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-name"
                  name="name"
                  label="Name"
                  required
                  placeholder="Your Name"
                  type="text"
                  value={teacher.name}
                />
                <TextInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-email"
                  name="email"
                  label="E-Mail"
                  required
                  placeholder="Your E-Mail Address"
                  type="email"
                  value={teacher.email}
                />
                <PasswordInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-password"
                  name="password"
                  label="Change password"
                  placeholder="New password"
                  value={teacher.password}
                />
                <br />
                <button
                  style={{ width: "14rem" }}
                  className="continue-button button button--medium"
                  type="submit"
                >
                  Save
                </button>
              </form>
            ) : (
              <>
                <p>Name: {teacher.name}</p>
                <p>E-Mail: {teacher.email}</p>
                <button
                  style={{ width: "min-content" }}
                  className="continue-button button button--medium"
                  onClick={() => setTeacherEditMode(true)}
                >
                  Edit
                </button>
              </>
            )}
            <h1 style={{ marginTop: "5vh" }}>School classes</h1>
            {teacher.schoolsByContact.nodes.map((school) => {
              return (
                <div key={school.id}>
                  {currentSchoolEdit === school.id ? (
                    <form
                      onSubmit={(e) =>
                        handleChangeSchoolNameSave({
                          e,
                          schoolId: school.id,
                        })
                      }
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "1rem",
                      }}
                    >
                      <TextInput
                        handleChange={(e) =>
                          handleChangeSchoolName({
                            e,
                            schoolId: school.id,
                          })
                        }
                        handleKeypress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault()
                            handleChangeSchoolNameSave({
                              e,
                              schoolId: school.id,
                            })
                          }
                        }}
                        id="school-name"
                        name="school-name"
                        label="school name"
                        required
                        placeholder="school name"
                        type="text"
                        value={school.name}
                      />
                      <br />
                      <button
                        style={{ width: "min-content", marginLeft: "1rem" }}
                        className="continue-button button button--medium"
                        type="submit"
                      >
                        Save
                      </button>
                    </form>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <h3>School: {school.name}</h3>
                      <EditButton
                        style={{
                          marginLeft: "1rem",
                        }}
                        onClick={() => handleSchoolEdit(school.id)}
                        tooltipText="Edit the name of your school."
                      />
                    </div>
                  )}
                  {school.classesBySchool.nodes.map((c) => {
                    return (
                      <details
                        key={c.id}
                        open={creatingNewClass && currentClassEdit === c.id}
                      >
                        <summary>class: {c.name}</summary>
                        <div>
                          {currentClassEdit === c.id ? (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault()
                                handleChangeClassSave({
                                  schoolId: school.id,
                                  classId: c.id,
                                })
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyItems: "start",
                                gap: "2vw",
                                marginTop: "1.5rem",
                                marginBottom: "2rem",
                              }}
                            >
                              <TextInput
                                handleChange={(e) =>
                                  handleChangeClassName({
                                    e,
                                    schoolId: school.id,
                                    classId: c.id,
                                  })
                                }
                                handleKeypress={(e) =>
                                  handleClassEditKeypress(e, school.id, c.id)
                                }
                                id="class-name"
                                name="class-name"
                                label="Class Name"
                                required
                                placeholder="Class Name"
                                type="text"
                                value={c.name}
                              />
                              <NumberInput
                                id="year"
                                label="Grade"
                                handleChange={(e) =>
                                  handleChangeClassYear(e, school.id, c.id)
                                }
                                min={1}
                                max={5}
                                value={c.year || 1}
                                small
                              />
                              <FormGroup>
                                <Label for="skill-select-global">
                                  Skill Level:
                                </Label>
                                <Input
                                  type="select"
                                  id="skill-select-global"
                                  value={globalSkillLevel}
                                  onChange={handleGlobalEstimatedSkillSelect}
                                >
                                  <option value="">
                                    --Please select skill level
                                  </option>
                                  <option value="BELOW_AVERAGE">
                                    BELOW_AVERAGE
                                  </option>
                                  <option value="AVERAGE">AVERAGE</option>
                                  <option value="ABOVE_AVERAGE">
                                    ABOVE_AVERAGE
                                  </option>
                                  <option value="HIGH">HIGH</option>
                                </Input>
                              </FormGroup>
                              <NumberInput
                                id="daily-target-global"
                                label="Daily Target"
                                handleChange={handleGlobalDailyTargetSelect}
                                min={10}
                                max={30}
                                value={globalDailyTarget}
                                small
                              />
                              <button
                                style={{
                                  width: "min-content",
                                }}
                                className="continue-button button button--medium"
                                type="submit"
                              >
                                Save
                              </button>
                            </form>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <EditButton
                                onClick={() =>
                                  handleClassEdit({
                                    classId: c.id,
                                    schoolId: school.id,
                                  })
                                }
                                tooltipText="Edit the class"
                              />
                              <DeleteButton
                                style={{
                                  marginLeft: "1rem",
                                }}
                                onClick={() =>
                                  handleClassDelete({
                                    schoolId: school.id,
                                    classId: c.id,
                                  })
                                }
                                tooltipText="Delete class"
                              />
                              <PDFDownloadButton
                                style={{ marginLeft: "1rem" }}
                                onClick={() => exportStudents(school.id, c.id)}
                                tooltipText="Download the student list of this class as PDF"
                              />
                              <DashboardButton
                                style={{ marginLeft: "1.5rem" }}
                                onClick={() =>
                                  navigate(`/teacher/dashboard?classId=${c.id}`)
                                }
                                tooltipText="Access teacher dashboard"
                              />
                            </div>
                          )}

                          {c.studentsByClass.nodes.length > 0 && (
                            <table
                              style={{
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Login</th>
                                  <th>Age</th>
                                  <th>Skill Level</th>
                                  <th>Daily Target</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {c.studentsByClass.nodes.map(
                                  (student, sIndex) => {
                                    return (
                                      <tr key={student.id}>
                                        <td>
                                          {currentClassEdit === c.id ? (
                                            <form>
                                              <TextInput
                                                handleChange={(e) =>
                                                  handleChangeStudentName({
                                                    e,
                                                    schoolId: school.id,
                                                    classId: c.id,
                                                    studentId: student.id,
                                                  })
                                                }
                                                handleKeypress={(e) => {
                                                  console.log("school:", school)
                                                  handleClassEditKeypress({
                                                    e,
                                                    schoolId: school.id,
                                                    classId: c.id,
                                                  })
                                                }}
                                                id="student-name"
                                                name="student-name"
                                                label=""
                                                required
                                                placeholder="student name"
                                                type="text"
                                                value={student.name}
                                              />
                                            </form>
                                          ) : (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "70%",
                                              }}
                                            >
                                              {student.name || "-"}
                                            </div>
                                          )}
                                        </td>
                                        <td>{student.login}</td>
                                        <td>{student.age || "-"}</td>
                                        <td>
                                          {currentClassEdit === c.id ? (
                                            <FormGroup>
                                              <Input
                                                type="select"
                                                id="skill-select"
                                                value={student.estimatedSkill}
                                                onChange={(e) => {
                                                  handleStudentEstimatedSkillSelect(
                                                    {
                                                      e,
                                                      schoolId: school.id,
                                                      classId: c.id,
                                                      studentId: student.id,
                                                    }
                                                  )
                                                }}
                                              >
                                                <option>BELOW_AVERAGE</option>
                                                <option>AVERAGE</option>
                                                <option>ABOVE_AVERAGE</option>
                                                <option>HIGH</option>
                                              </Input>
                                            </FormGroup>
                                          ) : (
                                            student.estimatedSkill
                                          )}
                                        </td>
                                        <td>
                                          {currentClassEdit === c.id ? (
                                            <NumberInput
                                              id={`daily-target-${sIndex}`}
                                              handleChange={(e) =>
                                                handleStudentDailyTargetSelect({
                                                  e,
                                                  schoolId: school.id,
                                                  classId: c.id,
                                                  studentId: student.id,
                                                })
                                              }
                                              min={10}
                                              max={30}
                                              value={student.dailyTarget}
                                              small
                                            />
                                          ) : (
                                            student.dailyTarget ?? "-"
                                          )}
                                        </td>
                                        <td>
                                          <DeleteButton
                                            style={{
                                              marginLeft: "0.5vw",
                                            }}
                                            onClick={() =>
                                              handleStudentDelete({
                                                schoolId: school.id,
                                                classId: c.id,
                                                studentId: student.id,
                                              })
                                            }
                                            tooltipText={`Delete the account of "${
                                              student.name ||
                                              student.alias ||
                                              ""
                                            }"`}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                              </tbody>
                            </table>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "1vh",
                            }}
                          >
                            <span>Add Students</span>
                            <AddButton
                              style={{
                                marginLeft: "1rem",
                              }}
                              onClick={() => handleStudentsAdd(school.id, c.id)}
                            />
                          </div>
                        </div>
                      </details>
                    )
                  })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1vh",
                      marginTop: "4vh",
                    }}
                  >
                    <span>Add Class</span>
                    <AddButton
                      style={{
                        marginLeft: "1rem",
                      }}
                      onClick={() => handleClassAdd(school.id)}
                    />
                  </div>
                </div>
              )
            })}
            <br />
            <div style={{ display: "flex", gap: "5rem", marginTop: "5rem" }}>
              <button
                style={{ width: "14rem" }}
                className="continue-button button button--medium logout"
                onClick={() => logout()}
              >
                Log Out
              </button>
              <button
                style={{ width: "14rem" }}
                className="danger-button button--medium"
                onClick={() => deleteAccount()}
              >
                Delete Account
              </button>
            </div>
          </>
        )}
      </ProfileLayout>
    </Layout>
  )
}

export default ProfilePage
