import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { fluidRange } from "polished"

import { BaseLayout } from "../../styles/common"
import { MainButton } from "../../styles/buttons"

import ArrowIcon from "../../lib/icons/Arrow"

const StyledProfileLayout = styled(BaseLayout)`
  padding: 0;
  align-content: stretch;
  display: flex;

  .panel {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    color: white;
    background-color: ${({ theme }) => theme.colors.primary.shade};
    box-shadow: 0px 10px 250px #36c7e7;
  }

  .panel-content {
    height: 100%;
    box-sizing: border-box;
    display: block;
    align-content: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacings.fluid.regular}vw;

    & details {
      margin-bottom: 1rem;
      border: 3px solid white;
      border-radius: 8px;
      padding: 12px 12px 12px 12px;

      & form select {
        margin-left: 8px;
        @media (orientation: portrait) {
          font-size: calc(100vw / (64 * 0.49) + 2px);
        }

        @media (orientation: landscape) {
          font-size: calc(100vw / 64);
        }
      }

      summary {
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    & table {
      @media (orientation: portrait) {
        margin-left: 3px;
      }
      table-layout: fixed;
      & thead th:nth-child(1) {
        width: 35%;
      }

      & thead th:nth-child(2) {
        width: 10%;
      }

      & thead th:nth-child(3) {
        width: 5%;
      }

      & thead th:nth-child(4) {
        width: 18%;
      }

      & thead th:nth-child(5) {
        width: 17%;
      }

      & thead th:nth-child(6) {
        width: 5%;
      }

      @media (orientation: landscape) {
        margin-left: 12px;
      }
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 12px;

      & thead th {
        text-align: start;

        @media (orientation: portrait) {
          font-size: calc(100vw / (64 * 0.49) + 2px);
        }

        @media (orientation: landscape) {
          font-size: calc(100vw / 64);
        }

        text-decoration: underline;
      }

      & tbody td {
        padding-bottom: 4px;
        text-align: start;

        & form {
          display: flex;
          align-items: center;
        }
        @media (orientation: portrait) {
          font-size: calc(100vw / (64 * 0.64) + 4px);
          & form {
            margin-bottom: 0;
          }
        }

        @media (orientation: landscape) {
          font-size: calc(100vw / 64);
          & form {
            margin-bottom: 1rem;
          }
        }

        & .form-select {
          padding-right: 0;
          width: 90%;
        }
      }

      tbody:before {
        content: "@";
        display: block;
        line-height: 12px;
        text-indent: -99999px;
      }
    }
  }

  .profile-container {
    margin-bottom: ${({ theme }) => theme.spacings.fluid.medium}vh;
  }

  .actions {
    display: grid;
    gap: ${({ theme }) => theme.spacings.fluid.small}vh;
    justify-items: start;
  }

  .back-button {
    position: absolute;
    left: 5vw;
    top: 10%;
    transform: translate(-50%, -50%);

    ${({ theme }) =>
      fluidRange({
        prop: "font-size",
        fromSize: theme.fontSizes.regular,
        toSize: theme.fontSizes.large,
      })}

    svg {
      transform: rotate(180deg);
    }
  }
`

const ProfileLayout = ({ backLinkTarget, children }) => {
  return (
    <StyledProfileLayout>
      <div className="panel">
        <div className="panel-content">{children}</div>
        <div className="back-button">
          <MainButton as={Link} to={backLinkTarget}>
            <ArrowIcon />
          </MainButton>
        </div>
      </div>
    </StyledProfileLayout>
  )
}

export default ProfileLayout
